import { useEffect, useRef, useState } from "react";
import { ImageUploadWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Find_Member_URL, Member_Image_Upload } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ImageUpload({showUploadModal, setShowUploadModal, values}) {

    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState('Select Member');
    const [memberDropdown, setMemberDropdown] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const [fetchMembers, setFetchMembers] = useState(true);

    const [existShow, setExistShow] = useState(true);
    const [existNumber, setExistNumber] = useState(true);

    const nameRef = useRef();


    useEffect(() => {
        const membersArray = values.split(', ').map(value => value.trim());
        axios.post(Find_Member_URL, membersArray, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            setMembers(res.data.members);
            if(res.data.exist === 0) {
                setExistShow(false);
            } else {
                setExistNumber(res.data.exist);
            }
            setFetchMembers(false);

        })
        .catch(err => {
            console.log(err);
        })
    }, [fetchMembers, values]);

    useEffect(() => {
        const membersArray = values.split(', ').map(value => value.trim());
        axios.post(Find_Member_URL, membersArray, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.exist === 0) {
                setExistShow(false);
            } else {
                setExistShow(true);
                setExistNumber(res.data.exist);
            }
        })
        .catch(err => {
            console.log(err);
        })
    },[values]);

    function handleDropDown() {
        setMemberDropdown(!memberDropdown);
    };

    function closeModal() {
        setShowUploadModal(false);
        setMemberDropdown(false);
        setPreview(null);
    };

    function handleFileSelect() {
        document.getElementById('fileInput').click();
    };

    const handleFile = (evt) => {
        const file = evt.target.files[0];
        setSelectedFile(file);
        
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        }
        reader.readAsDataURL(file);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('member', nameRef.current.value);

        axios.post(Member_Image_Upload, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.status == "200") {
                setSelectedMember('Select Member');
                setSelectedFile(null);
                setPreview(null);
                setFetchMembers(true);
                setExistShow(false);
                toast.success('Image Uploaded !!', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
        .catch(err => {
            console.log(err);
        })

    }; 

    return(
        <>
            <ImageUploadWrapper className={showUploadModal? 'active' : ''}>
                <div className="upload_box">
                    {existShow ? (
                        <span>{members.length === 0 ? 'All members are already existing in your database' : `${existNumber} members among this list are already exists in your database`}</span>
                    ) : null}
                    <form className="box_inner" onSubmit={handleSubmit}>
                        <div className="select_box">
                            <input type="text" id="member" name="member" ref={nameRef} required />
                            <div className="dropdown_btn" onClick={handleDropDown}>
                                <p>{selectedMember}</p>
                                <i className={`fa-solid fa-angle-down ${memberDropdown?'rotate':''}`}></i>
                            </div>
                            <div className={`dropdown ${memberDropdown? 'active':''}`}>
                                <div className="dropdown_inner">
                                    <ul>
                                        {
                                            members.map((member, index) => (
                                                <li key={index}
                                                  onClick={() => {
                                                    setSelectedMember(member)
                                                    document.getElementById('member').value = member
                                                    document.getElementById('member').click()
                                                    setMemberDropdown(false)
                                                  }}
                                                >{member}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <input type="file" id="fileInput" accept="image/jpg, image/jpeg, image/png" onChange={handleFile} required />
                        <div className="preview_box" onClick={handleFileSelect}>
                            <div className="upload_text">
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                                <p>Upload image here</p>
                            </div>
                            {preview && <div className="img_box"><img src={preview} alt="Preview" /></div>}
                        </div>

                        <div className="btn_sec">
                            <button onClick={closeModal}>Close</button>
                            <button type="submit">Upload</button>
                        </div>
                    </form>
                </div>
            </ImageUploadWrapper>
        </>
    );
}


export default ImageUpload;