import { useEffect, useRef, useState } from "react";
import { AddMovieWrapper } from "../Styles/Movie-Style";
import ImageUpload from "../Modals/ImageUploadModal";
import axios from "axios";
import { Upload_Movie_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function AddMovie() {

    const languages = ['Hindi','English','Bengali','Tamil','Telugu','Kannada','Malayalam','Marathi','Gujrati','Punjabi','Konkani','Tulu'];
    const [languageDropdown, setLanguageDropdown] = useState(false);
    const [selectLanguage, setSelectLanguage] = useState([]);

    const activities = ['On Screen','Upcoming'];
    const [activityDropdown, setActivityDropdown] = useState(false);
    const [selectActivity, setSelectActivity] = useState('');

    const types = ['Action','Adventure','Biography','Comedy','Drama','Fantasy','Horror','Romance','Sci-Fi','Sports','Thriller'];
    const [typeDropdown, setTypeDropdown] = useState(false);
    const [selectType, setSelectType] = useState([]);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [castError, setCastError] = useState(false);
    const [crewError, setCrewError] = useState(false);

    const [members, setMembers] = useState('');

    const [casts, setCasts] = useState('');
    const [crews, setCrews] = useState('');


    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [combinedTime, setCombinedTime] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const [formattedDate, setFormattedDate] = useState('');

    function handleFileSelect() {
        document.getElementById('poster_file').click();
    };

    const handleFile = (evt) => {
        const file = evt.target.files[0];
        setSelectedFile(file);
        
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        }
        reader.readAsDataURL(file);
    };

    const handleHourChange = (event) => {
        setHours(event.target.value);
        updateCombinedTime(event.target.value, minutes);
    };

    const handleMinuteChange = (event) => {
        setMinutes(event.target.value);
        updateCombinedTime(hours, event.target.value);
    };

    const updateCombinedTime = (hour, minute) => {
        setCombinedTime(`${hour}hr ${minute}min`);
    };

    const handleDate = (e) => {
        const date = new Date(e.target.value);
        setFormattedDate(formatDate(date));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/(\d+) (\w+) (\d+)/, '$1 $2, $3');
    };

    const nameRef = useRef();
    const languagesRef = useRef();
    const activityRef = useRef();
    const typesRef = useRef();
    const dateRef = useRef();
    const trailerRef = useRef();
    const aboutRef = useRef();
    


    function handleLanguageDropdown() {
        setLanguageDropdown(!languageDropdown);
        setActivityDropdown(false);
        setTypeDropdown(false);
    }

    const handleLanguage = (language) => {
        setSelectLanguage(prevState => {
            if (prevState.includes(language)) {
                return prevState.filter(lang => lang !== language);
            } else {
                return [...prevState, language];
            }
        });

        console.log(selectLanguage);
    };

    function handleActivityDropdown() {
        setActivityDropdown(!activityDropdown);
        setLanguageDropdown(false);
        setTypeDropdown(false);
    }

    const handleActivity = (activity) => {
        setSelectActivity(activity);
        setActivityDropdown(false);
    }

    function handleTypeDropdown() {
        setTypeDropdown(!typeDropdown);
        setLanguageDropdown(false);
        setActivityDropdown(false);
    }

    const handleType = (type) => {
        setSelectType(prevState => {
            if (prevState.includes(type)) {
                return prevState.filter(typ => typ !== type);
            } else {
                return [...prevState, type];
            }
        });

        console.log(selectLanguage);
    };


    
    const handleCasts = (e) => {
        const value = e.target.value;
        if (value.endsWith(',')) {
            setCasts(value + ' ');
        } else {
            setCasts(value);
        }
    };
    
    const handleCrews = (e) => {
        const value = e.target.value;
        if (value.endsWith(',')) {
            setCrews(value + ' ');
        } else {
            setCrews(value);
        }
    };

    function handleCastUploadModal() {
        if(document.getElementById('casts').value !== ""){
            setMembers(casts);
            setShowUploadModal(true);
        } else {
            setCastError(true);
        }
    }

    function handleCrewUploadModal() {
        if(document.getElementById('crews').value !== ""){
            setMembers(crews);
            setShowUploadModal(true);
        } else {
            setCrewError(true);
        }
    }


    const resetForm = () => {
        nameRef.current.value = '';
        setSelectLanguage([]);
        setSelectActivity('');
        setSelectType([]);
        dateRef.current.value = '';
        setFormattedDate('');
        setHours('');
        setMinutes('');
        setCombinedTime('');
        trailerRef.current.value = '';
        setCasts('');
        setCrews('');
        aboutRef.current.value = '';
        setSelectedFile(null);
        setPreview(null);
    };


    function handleSubmit(e) {
        e.preventDefault();

        const inputData = {
            name: nameRef.current.value,
            languages: languagesRef.current.value,
            activity: selectActivity,
            types: typesRef.current.value,
            date: formattedDate,
            time: combinedTime,
            trailer: trailerRef.current.value,
            casts: casts,
            crews: crews,
            about: aboutRef.current.value
        }

        const formData = new FormData();
        formData.append('inputs', JSON.stringify(inputData));
        formData.append('image', selectedFile);

        console.log(formData);
        axios.post(Upload_Movie_URL, formData)
        .then(res => {
            console.log(res);
            toast.success('New Movie Added !!', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            resetForm();
        })
        .catch(err => {
            console.log(err);
        })
    }

    return(
        <>
            <AddMovieWrapper>
                <div className="wrapper_inner">
                    <form onSubmit={handleSubmit}>
                        <div className="form_top">
                            <div className="left_part">
                                <input type="file" name="poster_file" id="poster_file" onChange={handleFile} accept="image/jpg, image/jpeg, image/png" required />
                                <div className="preview_box" onClick={handleFileSelect}>
                                    <div className="text_box">
                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                        <p>Upload your movie poster here</p>
                                    </div>
                                    <div className="img_box">
                                        {preview && <img src={preview} alt="Preview" />}
                                    </div>
                                </div>
                            </div>
                            <div className="right_part">
                                <div className="right_inner">
                                    <div className="input_box nameBox">
                                        <input type="text" name="name" ref={nameRef} required />
                                        <span>Name</span>
                                    </div>
                                    <div className="select_box languageBox">
                                        <input type="text" value={selectLanguage.join(', ')} id="language" ref={languagesRef} name="language" required />
                                        <div className="dropdown_btn" onClick={handleLanguageDropdown}>
                                            <span>Language</span>
                                            <p>{selectLanguage.join(', ')}</p>
                                            <i className={`fa-solid fa-angle-down ${languageDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${languageDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    languages.map((language,index) => (
                                                        <li key={index} >
                                                            <input 
                                                                type="checkbox"
                                                                value={language}
                                                                checked={selectLanguage.includes(language)}
                                                                name="language_choice"
                                                                id={language}
                                                                onChange={() => handleLanguage(language)}
                                                            />
                                                            <label htmlFor={language}>
                                                                <div className="check_box"><i className="fa-solid fa-check"></i></div>
                                                                <p>{language}</p>
                                                            </label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="select_box activityBox">
                                        <input type="text" value={selectActivity} id="activity" ref={activityRef} name="activity" required />
                                        <div className="dropdown_btn" onClick={handleActivityDropdown}>
                                            <span>Activity</span>
                                            <p>{selectActivity}</p>
                                            <i className={`fa-solid fa-angle-down ${activityDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${activityDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    activities.map((activity,index) => (
                                                        <li key={index} className="fullWidth">
                                                            <input 
                                                                type="checkbox"
                                                                value={activity}
                                                                checked={selectActivity === activity}
                                                                name="activity_choice"
                                                                id={activity}
                                                                onChange={() => handleActivity(activity)}
                                                            />
                                                            <label htmlFor={activity}>
                                                                <div className="check_box"><i className="fa-solid fa-check"></i></div>
                                                                <p>{activity}</p>
                                                            </label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="select_box typeBox">
                                        <input type="text" value={selectType.join(', ')} id="types" name="types" ref={typesRef} required />
                                        <div className="dropdown_btn" onClick={handleTypeDropdown}>
                                            <span>Types</span>
                                            <p>{selectType.join(', ')}</p>
                                            <i className={`fa-solid fa-angle-down ${typeDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${typeDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    types.map((type,index) => (
                                                        <li key={index}>
                                                            <input 
                                                                type="checkbox"
                                                                value={type}
                                                                checked={selectType.includes(type)}
                                                                name="type_choice"
                                                                id={type}
                                                                onChange={() => handleType(type)}
                                                            />
                                                            <label htmlFor={type}>
                                                                <div className="check_box"><i className="fa-solid fa-check"></i></div>
                                                                <p>{type}</p>
                                                            </label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="date_box">
                                        <span>Release Date:</span>
                                        <div className="date_input"><input type="date" id="date" ref={dateRef} onChange={handleDate} required /></div>
                                    </div>
                                    <div className="time_box">
                                        <span>Movie Time:</span>
                                        <div className="time_inputs">
                                            <div className="hour_input">
                                                <input type="text" value={hours} onChange={handleHourChange} required />
                                                <p>hour</p>
                                            </div>
                                            <div className="minute_input">
                                                <input type="text" value={minutes} onChange={handleMinuteChange} required />
                                                <p>minute</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form_bottom">
                            <div className="trailer_input_box">
                                <div className="input_box">
                                    <input type="text" ref={trailerRef} required />
                                    <span>Movie Trailer</span>
                                </div>
                            </div>
                            <div className="member_input_box">
                                <div className="input_box">
                                    <input type="text" name="casts" id="casts" placeholder="Enter all Casts names sepearte them by ' , '" value={casts} onChange={handleCasts} onKeyUp={() => setCastError(false)} required />
                                    {
                                        castError && <b>Please enter cast members name</b> 
                                    }
                                    <span>Casts</span>
                                </div>
                                <p onClick={handleCastUploadModal}><i className="fa-solid fa-cloud-arrow-up"></i>Upload Images</p>
                            </div>
                            <div className="member_input_box">
                                <div className="input_box">
                                    <input type="text" name="crews" id="crews" placeholder="Enter all Crews names sepearte them by ' , '" value={crews} onChange={handleCrews} onKeyUp={() => setCrewError(false)} required />
                                    {
                                        crewError && <b>Please enter crew members name</b> 
                                    }
                                    <span>Crews</span>
                                </div>
                                <p onClick={handleCrewUploadModal}><i className="fa-solid fa-cloud-arrow-up"></i>Upload Images</p>
                            </div>
                            <div className="about_input_box">
                                <textarea name="about" ref={aboutRef} required></textarea>
                                <span>About</span>
                            </div>
                            <div className="form_btn">
                                <button type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </AddMovieWrapper>

            <ImageUpload showUploadModal={showUploadModal} setShowUploadModal={setShowUploadModal} values={members} />                                    
        </>
    );
}


export default AddMovie;