import React, { useState } from 'react';
import { DashboardWrapper } from "../Styles/Dashboard-Style";
import UserData from '../Charts/UserData-Chart';
import MovieData from '../Charts/MovieData-Chart';
import TotalUserBookingData from '../Charts/TotalUserBookingData-Chart';
import MovieBookingData from '../Charts/MovieBookingData-Chart';
import { Link } from 'react-router-dom';







function Dashboard() {

    return(
        <>
            <DashboardWrapper>
                <div className="dashboard_inner">
                    <div className="dashboard_box_sec">
                        <Link to="/panel/users" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total No. Users</h4>
                                    <p>255</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-users"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/theaters-listed" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total No. Theaters</h4>
                                    <p>255</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-clapperboard"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/movies" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Uploaded Movies</h4>
                                    <p>155</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-film"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/today-showing" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Today Showing</h4>
                                    <p>55</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-video"></i>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="superadmin_graph_sec">
                        <div className="user_graph">
                            <h4>Users & Bookings ( Weekly )</h4>
                            <div className="graph_box">
                                <UserData />
                            </div>
                        </div>
                        <div className="movie_graph">
                            <h4>Movies' Data</h4>
                            <div className="graph_box">
                                <MovieData />
                            </div>
                        </div>
                        <div className="Booking_graph">
                            <h4>Users & Bookings ( Total )</h4>
                            <div className="graph_box">
                                <TotalUserBookingData />
                            </div>
                        </div>
                        <div className="movie_booking_graph">
                            <h4>Movies & Bookings ( Weekly )</h4>
                            <div className="graph_box">
                                <MovieBookingData />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardWrapper>
        </>
    );

}


export default Dashboard;