import { styled } from "@mui/material";


export const PanelLayout = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;

    .left_side {
        position: relative;
        width: 300px;
        height: 100%;
    }

    .right_side {
        position: relative;
        width: calc(100% - 300px);
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .top_bar {
            position: relative;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #E1E0EA;
        }

        .main_content {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            overflow: hidden;
            padding: 60px 10px;
            padding-top: 40px;

            .content_inner {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0px 50px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #FC6736;
                    border-radius: 10px;
                }

                .inner_items {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`;