import { useState } from "react";
import ReactApexChart from 'react-apexcharts';



function UserData() {

    const [series, setSeries] = useState([
        {
            name: 'Users',
            data: [31, 240, 110, 51, 42, 109, 100],
            color: "#3FE44F",
        },
        {
            name: 'Booking',
            data: [70, 32, 185, 80, 34, 52, 41],
            color: "#02C0FF",
        },
    ]);

    const [options, setOptions] = useState({
        chart: {
            height: 300,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        grid: {
            show: false,
        },
        xaxis: {
            categories: [
                "26 May",
                "27 May",
                "28 May",
                "29 May",
                "30 May",
                "1 Apr",
                "2 Apr"
            ],
            labels: {
             format: 'dd/MM/yy'  // Format x-axis labels to display only date
            }
        },
        yaxis: {
            min: 0,  // Set minimum value for y-axis
            max: 250, 
            // show: false
        },
        tooltip: {
            x: {
            format: 'dd/MM/yy HH:mm',
            },
        },
        legend: {
            show: false
        }
    });

    return(
        <>
            <div id="chart">
                <ReactApexChart  options={options} series={series} type="area" height={300} />
            </div>
            <div id="html-dist"></div>
        </>
    );
}


export default UserData;