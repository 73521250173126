import { useEffect, useState } from "react";
import { MovieWrapper } from "../Styles/Movie-Style";
import { Link } from "react-router-dom";
import axios from "axios";
import { Delete_Movie_URL, Show_Movies_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function Movies() {

    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [filterType, setFilterType] = useState('Select');
    const filterTypes = ['Movie Name', 'Type', 'Activity'];

    const [count, setCount] = useState(false);
    const [movies, setMovies] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredMovies, setFilteredMovies] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        axios.get(Show_Movies_URL)
        .then(res => {
            // console.log(res);
            if(res.data.count > 0) {
                setCount(true);
                setMovies(res.data.movies);
                setFilteredMovies(res.data.movies);
            } else {
                setCount(false);
            }
        })
        .catch(err => {
            console.log(err);
            setCount(false);
        })
    }, [reload]);


    function handleFilterDropdown() {
        setShowFilterDropdown(!showFilterDropdown);
    }

    useEffect(() => {
        handleSearch();
    }, [searchInput, filterType]);

    const handleSearch = () => {
        if (filterType === 'Select' || searchInput.trim() === '') {
            setFilteredMovies(movies);
            return;
        } 
        
        const filtered = movies.filter(movie => {
            if (filterType === 'Movie Name') {
                return movie.name.toLowerCase().includes(searchInput.toLowerCase());
            } else if (filterType === 'Type') {
                return movie.types.toLowerCase().includes(searchInput.toLowerCase());
            } else if (filterType === 'Activity') {
                return movie.activity.toLowerCase().includes(searchInput.toLowerCase());
            } else {
                return false;
            }
        });

        setFilteredMovies(filtered);
    };


    const deleteMovie = (id) => {

        let input = {
            index: id
        }

        axios.post(Delete_Movie_URL, input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            toast.warn('You have Deleted a Movie !!', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setReload(true);
        })
        .catch(err => {
            console.log(err);
        })

    }

    return(
        <>
            <MovieWrapper>
                <div className="filter_search_sec">
                    <div className="filter_box">
                        <h5>Search By:</h5>
                        <div className="select_box">
                            <input type="text" id="filter" name="filter" required />
                            <div className="dropdown_btn" onClick={handleFilterDropdown}>
                                <p>{filterType}</p>
                                <i class={`fa-solid fa-angle-down ${showFilterDropdown? 'active':''}`}></i>
                            </div>
                            <div className={`dropdown ${showFilterDropdown? 'active':''}`}>
                                <ul>
                                    {
                                        filterTypes.map((filterType, index) => (
                                            <li key={index} onClick={(e) => {
                                                setFilterType(filterType)
                                                setShowFilterDropdown(false)
                                                document.getElementById('filter').value = filterType
                                                document.getElementById('filter').click()
                                            }}>{filterType}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search_box">
                        <h5>Search:</h5>
                        <input type="text" placeholder="Enter Search Input" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}  name="search" required />
                    </div>
                    <div className="add_btn">
                        <Link to="/panel/add-movie"><i className="fa-solid fa-plus"></i>Add Movie</Link>
                    </div>
                </div>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>Movie Name</th>
                                <th>Types</th>
                                <th>Total Time</th>
                                <th>Activity</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    count ? 
                                    (filteredMovies  && filteredMovies.map((movie, index) => 
                                    <tr>
                                        <td>{index + 1}.</td>
                                        <td>{movie.name}</td>
                                        <td>{movie.types}</td>
                                        <td>{movie.total_time}</td>
                                        <td>{movie.activity}</td>
                                        <td>
                                            <span className="view"><i class="fa-solid fa-eye"></i></span>
                                            <span className="delete" onClick={() => deleteMovie(movie.id)}><i class="fa-solid fa-trash"></i></span>
                                        </td>
                                    </tr>))
                                    :
                                    <tr>
                                        <p>No Movies Found</p>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </MovieWrapper>
        </>
    );
}


export default Movies;