import { styled } from "@mui/material";


export const LoginWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 50px;
    background: linear-gradient(135deg, #E1E0EA 0%, #FFFF6C 40%, #FFC76C 70%, #FC6736 100%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(100px);
    }

    .login_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .heading {
            position: relative;

            h1 {
                position: relative;
                font-family: arista-pro-trial-bold;
                font-size: 45px;
                color: #000;

                span {
                    font-family: 'Oleo Script', cursive;
                }
            }
        }

        .login_box {
            position: relative;
            margin-top: 90px;
            width: 450px;
            height: 430px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px;

            .square {
                position: absolute;
                backdrop-filter: blur(5px);
                box-shadow: 0 25px 45px rgba(0,0,0,0.1);
                border: 1px solid rgba(255,255,255,0.5);
                border-right: 1px solid rgba(255,255,255,0.2);
                border-bottom: 1px solid rgba(255,255,255,0.2);
                background: rgba(255,255,255,0.1);
                border-radius: 10px;
                animation: sqanimation 6s linear infinite;
                animation-delay: calc(-1s * var(--i));

                &:nth-child(1) {
                    top: -10px;
                    right: -20px;
                    width: 100px;
                    height: 100px;
                }

                &:nth-child(2) {
                    top: 200px;
                    left: -100px;
                    width: 120px;
                    height: 120px;
                    z-index: 2;
                }

                &:nth-child(3) {
                    bottom: 50px;
                    right: -50px;
                    width: 80px;
                    height: 80px;
                    z-index: 2;
                }

                &:nth-child(4) {
                    bottom: -15px;
                    left: 100px;
                    width: 50px;
                    height: 50px;
                }

                &:nth-child(5) {
                    top: -20px;
                    left: -40px;
                    width: 60px;
                    height: 60px;
                }

                @keyframes sqanimation
                {
                    0%,100%
                    {
                        transform: translateY(-10px);
                    }
                    50%
                    {
                        transform: translateY(30px);
                    }
                }
            }

            .forms_inner {
                position: relative;
                width: 100%;
                height: 100%;

                form {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background: rgba(255, 255, 255, 0.171);
                    border-radius: 10px;
                    backdrop-filter: blur(5px);
                    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
                    border: 1px solid rgba(255,255,255,0.5);
                    border-right: 1px solid rgba(255,255,255,0.2);
                    border-bottom: 1px solid rgba(255,255,255,0.2);
                    padding: 30px;

                    .form_head {
                        position: relative;

                        h3 {
                            font-family: 'Lemonada', cursive;
                            font-size: 20px;
                            line-height: 1;
                            font-weight: 700;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -10px;
                                left: 0;
                                width: 40px;
                                height: 3px;
                                background: #FC6736;
                                border-radius: 20px;
                            }
                        }
                    }

                    .form_input_sec {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 25px;

                        .inputBox {
                            position: relative;
                            width: 100%;
                            margin-top: 20px;

                            input {
                                position: relative;
                                width: 100%;
                                padding: 8px 0;
                                background: transparent;
                                border: none;
                                border-bottom: 1px solid rgb(200, 200, 200);
                                outline: none;
                                color: #222;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;

                                &:focus,
                                &:valid {
                                    border-bottom: 1px solid #FC6736;
                                }
                            }

                            label {
                                position: absolute;
                                top: 0;
                                left: 0;
                                padding: 8px 0;
                                pointer-events: none;
                                color: #555;
                                text-transform: uppercase;
                                font-size: 14px;  
                                font-family: 'Poppins', sans-serif;

                                span {
                                    position: relative;
                                    display: inline-flex;
                                    letter-spacing: 0.5px;
                                    transition: 0.2s ease-in-out;
                                }
                            }

                            input:focus ~ label span,
                            input:valid ~ label span {
                                color: #FC6736;
                                letter-spacing: 1px;
                                transform: translateY(-20px);
                                font-weight: 600;
                                font-size: 11px;
                            }

                            a {
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                color: #555;
                                font-size: 16px;
                            }

                            button {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                margin-top: 10px;
                                border: none;
                                background: #FC6736;
                                border-radius: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                font-weight: 600;
                                color: #FFF;
                                text-transform: uppercase;
                                cursor: pointer;
                                letter-spacing: 0.7px;
                                transition: all 0.5s;

                                &:hover {
                                    box-shadow: 3px 8px 15px rgba(5, 5, 5, 0.342);
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;