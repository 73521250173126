import { useEffect, useRef, useState } from "react";
import { LoginWrapper } from "../Styles/Login-Style";
import VanillaTilt from "vanilla-tilt";
import axios from "axios";
import { Login_URL } from "../API/Api";
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function Login() {

    const [showPassword, setShowPassword] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        password: '',
    });

    const tiltRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (tiltRef.current) {
            VanillaTilt.init(tiltRef.current, {
            max: 15,
            speed: 400,
            glare: true,
            "max-glare": 0.5
            });
        }
        return () => {
            if (tiltRef.current?.vanillaTilt) {
            tiltRef.current.vanillaTilt.destroy();
            }
        };
    }, []);

    useEffect(() => {
        let label = document.querySelectorAll('.formInputLabel');

        label.forEach(label => {
            label.innerHTML = label.innerText.split('').map((letters, i) => `<span style="transition-delay: ${i * 50}ms;">${letters}</span>`).join('');
        })
    },[]);

    function handlePassword() {
        setShowPassword(!showPassword);
    }

    const handleInput = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    }

    function handleSubmit(e) {
        e.preventDefault();
        axios.post(Login_URL, credentials, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data);
            let status = res.data.status

            if(status == "200") {
                localStorage.setItem("Login", "true");
                navigate('/panel');
                toast.success('Successfully Logged In !!', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    return(
        <>
            <LoginWrapper>
                <div className="login_inner">
                    <div className="heading"><h1>Super <span>Admin</span></h1></div>
                    <div className="login_box">
                        {[...Array(5)].map((_, i) => (
                            <div key={i} className="square" style={{ '--i': i }}></div>
                        ))}
                        <div className="forms_inner" ref={tiltRef} data-tilt>
                            <form onSubmit={handleSubmit}>
                                <div className="form_head"><h3>Signin</h3></div>
                                <div className="form_input_sec">
                                    <div className="inputBox">
                                        <input type="text" name="name" value={credentials.name || ""} onChange={handleInput} required />
                                        <label className="formInputLabel">Username</label>
                                    </div>
                                    <div className="inputBox">
                                        <input type={showPassword ? 'text' : 'password'} name="password" value={credentials.password || ""}  onChange={handleInput} required />
                                        <label className="formInputLabel">Password</label>
                                        <a onClick={handlePassword}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                                    </div>
                                    <div className="inputBox">
                                        <button>Sign In</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </LoginWrapper>
        </>
    );
}


export default Login;