import { useEffect, useState } from "react";
import { RequestMovieWrapper } from "../Styles/Movie-Style";
import { Link } from "react-router-dom";
import axios from "axios";
import { Requested_Movies_URL } from "../API/Api";




function RequestedMovies() {

    const [count, setCount] = useState(false);
    const [movies, setMovies] = useState([]);


    useEffect(() => {

        axios.get(Requested_Movies_URL)
        .then(res => {
            // console.log(res);
            if(res.data.count > 0){
                setCount(true);
                setMovies(res.data.requested_movies);
            } else {
                setCount(false);
            }
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, []);

    return(
        <>
            <RequestMovieWrapper>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>Theater Name</th>
                                <th>Movie Name</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    count ?
                                    (movies && movies.map((movie, index) => 
                                        <tr>
                                            <td>{index + 1}.</td>
                                            <td>{movie.theater_name}</td>
                                            <td>{movie.movie_name}</td>
                                            <td>
                                                <Link to="/panel/add-movie" className="add"><i className="fa-solid fa-square-plus"></i></Link>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <p>No Request Found</p>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </RequestMovieWrapper>
        </>
    );
}


export default RequestedMovies;