import { useEffect, useState } from "react";
import { SidebarWrapper } from "../Styles/Navigations-Style";
import { NavLink, useLocation } from "react-router-dom";




function Sidebar({setPageName}) {

    const location = useLocation();

    const [theaterDropdown, setTheaterDropdown] = useState(false);
    const [movieDropdown, setMovieDropdown] = useState(false);


    useEffect(() => {

        const formatPathName = (pathName) => {
            return pathName.split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        const pathName = location.pathname.split('/').filter(Boolean).pop() || 'Dashboard';
        const formattedPageName = formatPathName(pathName);

         if(pathName == "theaters-listed" || pathName == "theaters-requested"){
            setTheaterDropdown(true);
        } else {
            setTheaterDropdown(false);
        }

         if(pathName == "movies" || pathName == "today-showing" || pathName == "requested-movies" || pathName == "add-movie" || pathName == "reviews"){
            setMovieDropdown(true);
        } else {
            setMovieDropdown(false);
        }

        setPageName(formattedPageName);
        // console.log(pathName);
    },[location]);

    function handleTheaterDropdown() {
        setTheaterDropdown(!theaterDropdown);
        setMovieDropdown(false);
    }

    function handleMoieDropdown() {
        setMovieDropdown(!movieDropdown);
        setTheaterDropdown(false);
    }

    function closeDropdowns() {
        setTheaterDropdown(false);
    }


    return(
        <>
            <SidebarWrapper>
                <div className="sidebar_logo">
                    <img src="/images/Logo.png" alt="logo" />
                    <h3>Ticket <span>Counter</span></h3>
                </div>
                <div className="sidebar_items">
                    <NavLink to="/panel/dashboard" onClick={closeDropdowns}>
                        <span></span>
                        <span></span>
                        <p><i className="fa-solid fa-gauge"></i>Dashboard</p>
                    </NavLink>
                    <div className="dropdown_item">
                        <div className="dropdown_btn">
                            <li onClick={handleTheaterDropdown}><i className="fa-solid fa-clapperboard"></i>Theaters <i className={`fa-solid fa-chevron-right ${theaterDropdown? 'rotate':''}`}></i></li>
                        </div>
                        <div className={`dropdown ${theaterDropdown? 'active':''}`}>
                            <NavLink to="/panel/theaters-listed">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-list-ul"></i>Listed</p>
                            </NavLink>
                            <NavLink to="/panel/theaters-requested">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-code-pull-request"></i>Requested</p>
                            </NavLink>
                        </div>
                    </div>
                    <NavLink to="/panel/users">
                        <span></span>
                        <span></span>
                        <p><i className="fa-solid fa-users"></i>Users</p>
                    </NavLink>
                    <div className="dropdown_item">
                        <div className="dropdown_btn">
                            <li onClick={handleMoieDropdown}><i className="fa-solid fa-film"></i>Movies <i className={`fa-solid fa-chevron-right ${movieDropdown? 'rotate':''}`}></i></li>
                        </div>
                        <div className={`dropdown ${movieDropdown? 'active':''}`}>
                            <NavLink to="/panel/add-movie">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-plus"></i>Add Movie</p>
                            </NavLink>
                            <NavLink to="/panel/movies">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-video"></i>All Movies</p>
                            </NavLink>
                            <NavLink to="/panel/today-showing">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-compact-disc"></i>Now Showing</p>
                            </NavLink>
                            <NavLink to="/panel/requested-movies">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-code-pull-request"></i>Requested Movies</p>
                            </NavLink>
                            <NavLink to="/panel/reviews">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-star-half-stroke"></i>Reviews</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </SidebarWrapper>
        </>
    );
}


export default Sidebar;