import { styled } from "@mui/material";


export const TheaterWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter_search_sec {
        position: relative;
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter_box {
            position: relative;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            .select_box {
                position: relative;
                height: 100%;
                margin-left: 25px;

                input {
                    display: none;
                }

                .dropdown_btn {
                    position: relative;
                    width: 225px;
                    height: 100%;
                    border: 2px solid #E1E0EA;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 25px;
                    font-family: 'Poppins', sans-serif;
                    cursor: pointer;

                    p {
                        position: relative;
                        font-size: 16px;
                        color: #444;
                    }

                    i{
                        position: relative;
                        color: #444;
                        transition: all 0.5s;

                        &.active {
                            transform: rotate(-180deg);
                            transition: all 0.5s;
                        }
                    }
                }

                input:focus ~ .dropdown_btn,
                input:valid ~ .dropdown_btn {
                    border: 2px solid #FC6736;
                }

                .dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: #FFF;
                    z-index: 50;
                    border-radius: 5px;
                    box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.6s;

                    &.active {
                        max-height: 300px;
                        transition: all 0.6s;
                    }

                    ul {
                        position: relative;
                        width: 100%;
                        padding: 10px 10px;

                        li {
                            position: relative;
                            list-style: none;
                            margin: 5px 0;
                            padding: 10px 15px;
                            color: #555;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            line-height: 1;
                            border-radius: 4px;
                            transition: all 0.5s;
                            cursor: pointer;
                            
                            &:hover {
                                background: #FC6736;
                                color: #FFF;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }

        .search_box {
            position: relative;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            input {
                position: relative;
                margin-left: 25px;
                width: 400px;
                height: 100%;
                border: 2px solid #E1E0EA;
                border-radius: 8px;
                outline: none;
                padding: 5px 25px;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                color: #444;

                &:focus,
                &:valid {
                    border: 2px solid #FC6736;
                }
            }
        }
    }

    .table_sec {
        position: relative;
        width: 100%;
        height: calc(100% - 55px);
        padding-top: 40px;

        .table_inner {
            position: relative;
            width: 100%;
            height: 100%;

            table {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                thead {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    display: flex;
                    background: #FC6736;
                    border-radius: 10px 10px 0 0 ;
                    border: 1px solid #FC6736;

                    th {
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-family: 'Lemonada', cursive;
                        font-size: 14px;
                        color: #FFF;
                        font-weight: 600;

                        &:nth-child(1) {
                            width: 7%;
                            padding: 0px 25px;
                        }

                        &:nth-child(2) {
                            width: 30%;
                            padding: 0px 25px;
                        }

                        &:nth-child(3) {
                            width: 15%;
                            padding: 0px 25px;
                        }

                        &:nth-child(4) {
                            width: 13%;
                            justify-content: center;
                            text-align: center;
                        }

                        &:nth-child(5) {
                            width: 15%;
                            justify-content: center;
                            padding: 0px 25px;
                            text-align: center;
                        }

                        &:nth-child(6) {
                            width: 10%;
                            justify-content: center;
                            padding: 0px 25px;
                            text-align: center;
                        }

                        &:nth-child(7) {
                            width: 10%;
                            justify-content: center;
                            padding: 0px 25px;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    position: relative;
                    width: 100%;
                    max-height: calc(100% - 55px);
                    border: 1px solid #E1E0EA;
                    border-radius: 0 0 10px 10px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    scrollbar-width: none;

                    tr {
                        position: relative;
                        width: 100%;
                        min-height: 55px;
                        display: flex;
                        border-bottom: 1px solid #E1E0EA;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        &:nth-of-type(even) {
                            background: rgba(2, 192, 255, 0.15);
                        }

                        p {
                            position: relative;
                            width: 100%;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;
                        }

                        td {
                            position: relative;
                            padding: 15px 25px;
                            display: flex;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;

                            &:nth-child(1) {
                                width: 7%;
                            }

                            &:nth-child(2) {
                                width: 30%;
                            }

                            &:nth-child(3) {
                                width: 15%;
                            }

                            &:nth-child(4) {
                                width: 13%;
                                justify-content: center;
                                text-align: center;
                            }

                            &:nth-child(5) {
                                width: 15%;
                                justify-content: center;
                                text-align: center;
                            }

                            &:nth-child(6) {
                                width: 10%;
                                justify-content: center;
                                text-align: center;
                            }

                            &:nth-child(7) {
                                width: 10%;
                                justify-content: center;
                                align-items: center;

                                span {
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 16px;

                                    &.view {
                                        color: #02C0FF;
                                    }

                                    &.delete {
                                        margin-left: 10px;
                                        color: #FF33A1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;