import { useEffect, useState } from "react";
import { ModalTheaterDetailsBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Theater_Details_URL } from "../API/Api";



function ModalTheaterDetails({theaterDetailsModalShow, setTheaterDetailsModalShow, theaterName, setTheaterName}) {
    
    const [screenNo, setScreenNo] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [onlineBookings, setOnlineBookings] = useState('');
    const [offlineBookings, setOfflineBookings] = useState('');
    const [count, setCount] = useState(false);
    const [shows, setShows] = useState([]);

    useEffect(() => {

        axios.get(`${Fetch_Theater_Details_URL}?name=${theaterName}`)
        .then(res => {
            console.log(res);
            setScreenNo(res.data.screen_no);
            setGstNo(res.data.gst_no);
            setOnlineBookings(res.data.online_bookings);
            setOfflineBookings(res.data.offline_bookings);
            const moviesWithFormattedDate = res.data.shows.map(show => ({
                ...show,
                formatted_date: show.date.split(',')[0] // Use the backend formatted_date or extract it here
            }));

            if(res.data.count > 0){
                setCount(true);
                setShows(moviesWithFormattedDate);
            } else {
                setCount(false);
                setShows([]);
            }
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, [theaterName]);

    const closeModal = () => {
        setTheaterName('');
        setTheaterDetailsModalShow(false);
    };

    return(
        <>
            <ModalTheaterDetailsBox className={theaterDetailsModalShow ? 'active' : ''}>
                <div className={`details_inner ${theaterDetailsModalShow ? 'active' : ''}`}>
                    <div className="top_part">
                        <div className="top_inner">
                            <h3>{theaterName}</h3>
                        </div>
                    </div>
                    <div className="body_part">
                        <div className="info_box_sec">
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Total No. Screens</h4>
                                        <p>{screenNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-clapperboard"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>GST No.</h4>
                                        <p>{gstNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-file-shield"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Online Bookings</h4>
                                        <p>{onlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-house-signal"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Offline Bookings</h4>
                                        <p>{offlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table_sec">
                            <div className="sec_inner">
                                <table>
                                    <thead>
                                        <th>Sl. No.</th>
                                        <th>Movie Name</th>
                                        <th>Language</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Ticked Booked</th>
                                    </thead>
                                    <tbody>
                                        {
                                            count ?
                                            (shows && shows.map((show, index) => 
                                                <tr key={index}>
                                                    <td>{index + 1}.</td>
                                                    <td>{show.movie_name}</td>
                                                    <td>{show.language}</td>
                                                    <td>{show.formatted_date}</td>
                                                    <td>{show.time}</td>
                                                    <td>{show.total_booking}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <p>No Movies Found</p>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="button_sec">
                            <a onClick={closeModal}><span><i class="fa-solid fa-arrow-left-long"></i>Back</span></a>
                        </div>
                    </div>
                </div>
            </ModalTheaterDetailsBox>
        </>
    );
}


export default ModalTheaterDetails;