import { useState } from "react";
import { Nav } from "../Styles/Navigations-Style";
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function Navbar({pageName}) {

    const navigate = useNavigate();
    const [notificationDropdown, setNotificationDropdown] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);


    function handleNotificationDropdown() {
        setProfileDropdown(false);
        setNotificationDropdown(!notificationDropdown);
    }

    function handleProfileDropdown() {
        setNotificationDropdown(false);
        setProfileDropdown(!profileDropdown);
    }

    function logout() {
        localStorage.setItem("Login", "false");
        navigate('/');
        toast.warn('You have logged out !!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }

    return(
        <>
            <Nav>
                <div className="nav_inner">
                    <div className="side_bar_toggler"><i className="fa-solid fa-bars"></i></div>
                    <div className="page_name"><h3>{pageName}</h3></div>
                    <div className="nav_items">
                        <div className="notification">
                            <div className="notification_btn" onClick={handleNotificationDropdown}><i className="fa-solid fa-bell"></i></div>
                            <div className={`notification_dropdown ${notificationDropdown? 'active':''}`}>
                                <div className="dropdown_inner">
                                    <ul>
                                        <li className="unread"><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li className="unread"><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li className="unread"><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                        <li><p>Lorem ipsum dolor sit amet.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="profile">
                            <div className="profile_btn" onClick={handleProfileDropdown}>
                                <div className="user_img"><img src="/images/profile.png" alt="profile" /></div>
                                <p>Super Admin</p>
                                <i class={`fa-solid fa-caret-down ${profileDropdown? 'rotate':''}`}></i>
                            </div>
                            <div className={`profile_dropdown ${profileDropdown? 'active':''}`}>
                                <li onClick={logout}><i class="fa-solid fa-right-from-bracket"></i>Logout</li>
                            </div>
                        </div>
                    </div>
                </div>
            </Nav>
        </>
    );
}


export default Navbar;