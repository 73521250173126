import { useEffect, useState } from "react";
import { TheaterWrapper } from "../Styles/Theater-Style";
import axios from "axios";
import { Active_Theaters_URL } from "../API/Api";
import ModalTheaterDetails from "../Modals/TheaterDetails";




function Theaters() {

    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [filterType, setFilterType] = useState('Select');
    const filterTypes = ['Theater Name', 'City'];
    const [count, setCount] = useState(false);
    const [theaters, setTheaters] = useState([]);
    const [filteredTheaters, setFilteredTheaters] = useState([]);
    const [theaterName, setTheaterName] = useState('');
    const [theaterDetailsModalShow, setTheaterDetailsModalShow] = useState(false);


    function handleFilterDropdown() {
        setShowFilterDropdown(!showFilterDropdown);
    }

    useEffect(() => {
        axios.get(Active_Theaters_URL)
        .then(res => {
            console.log(res);
            if(res.data.count > 0) {
                setCount(true);
                setTheaters(res.data.theaters);
                setFilteredTheaters(res.data.theaters);
            } else {
                setCount(false);
            }
        })
        .catch(err => {
            console.log(err);
            setCount(false);
        })
    }, []);


    const handleTheaterDetails = (name) => {
        setTheaterName(name);
        setTheaterDetailsModalShow(true);
    };

    return(
        <>
            <TheaterWrapper>
                <div className="filter_search_sec">
                    <div className="filter_box">
                        <h5>Search By:</h5>
                        <div className="select_box">
                            <input type="text" id="filter" name="filter" required />
                            <div className="dropdown_btn" onClick={handleFilterDropdown}>
                                <p>{filterType}</p>
                                <i class={`fa-solid fa-angle-down ${showFilterDropdown? 'active':''}`}></i>
                            </div>
                            <div className={`dropdown ${showFilterDropdown? 'active':''}`}>
                                <ul>
                                    {
                                        filterTypes.map(filterType => (
                                            <li onClick={(e) => {
                                                setFilterType(filterType)
                                                setShowFilterDropdown(false)
                                                document.getElementById('filter').value = filterType
                                                document.getElementById('filter').click()
                                            }}>{filterType}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search_box">
                        <h5>Search:</h5>
                        <input type="text" placeholder="Enter Search Input" name="search" required />
                    </div>
                </div>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>Theater Name</th>
                                <th>Contact No.</th>
                                <th>No. of Screens</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    count ? 
                                    (filteredTheaters && filteredTheaters.map((theater, index) => 
                                        <tr>
                                            <td>{index + 1}.</td>
                                            <td>{theater.name}</td>
                                            <td>{theater.contact_no}</td>
                                            <td>{theater.screen_no}</td>
                                            <td>{theater.city}</td>
                                            <td>Active</td>
                                            <td>
                                                <span className="view" onClick={() => handleTheaterDetails(theater.name)}><i class="fa-solid fa-eye"></i></span>
                                                <span className="delete"><i class="fa-solid fa-trash"></i></span>
                                            </td>
                                        </tr>
                                    )) 
                                    :
                                    <tr>
                                        <p>No Data Found</p>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </TheaterWrapper>

            <ModalTheaterDetails theaterDetailsModalShow={theaterDetailsModalShow} setTheaterDetailsModalShow={setTheaterDetailsModalShow} theaterName={theaterName} setTheaterName={setTheaterName} />
        </>
    );
}


export default Theaters;